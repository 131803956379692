.main-container {
}

.address-form {
    .label {
        font-weight: 400;
        font-size: 14px;
        color: #5A7184;
        margin-bottom: 5px;

        a {
            text-decoration: none;
            color: #47C1BF;
        }
    }


}

.forgot-note {
    font-size: 12px;
    color: #5A7184;
    text-align: center !important;
    width: 100%;
}

.header {
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
}

.account-page {
    width: 100%;
    background-color: #FFFFFF;
    &-content {
        padding: 50px 150px;
        
    }
    .content-side {
        width: 100%;
    }
}

.add {
    color: #47C1BF;
}
.menu-side {
    width: 350px;
    padding: 15px;

    .account-menu-item {
                
        border-bottom: 1px solid #F4F6F8;
        padding: 10px 0;
        color: #919EAB;
        cursor: pointer;

        &:last-child {
            border-bottom: 0;
        }
    }
}

.ql-editor {
	min-height: 12em;
  }
  .ql-container {
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	background: #fefcfc;
  }
  .ql-snow.ql-toolbar {
	display: block;
	background: #eaecec;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
  }

/* Set dropdown font-families */
.ql-toolbar .ql-font span[data-label='Sailec Light']::before {
  font-family: 'Sailec Light';
}
.ql-toolbar .ql-font span[data-label='Sofia Pro']::before {
  font-family: 'Sofia';
}
.ql-toolbar .ql-font span[data-label='Slabo 27px']::before {
  font-family: 'Slabo 27px';
}
.ql-toolbar .ql-font span[data-label='Roboto Slab']::before {
  font-family: 'Roboto Slab';
}
.ql-toolbar .ql-font span[data-label='Inconsolata']::before {
  font-family: 'Inconsolata';
}
.ql-toolbar .ql-font span[data-label='Ubuntu Mono']::before {
  font-family: 'Ubuntu Mono';
}

/* Set content font-families */
.ql-font-sofia {
  font-family: 'Sofia';
}
.ql-font-slabo {
  font-family: 'Slabo 27px';
}
.ql-font-roboto {
  font-family: 'Roboto Slab';
}
.ql-font-inconsolata {
  font-family: 'Inconsolata';
}
.ql-font-ubuntu {
  font-family: 'Ubuntu Mono';
}

// Quill Editor
.ql-toolbar {
  border-color: #d8d6de !important;
  .ql-formats {
    &:focus,
    *:focus {
      outline: 0;
    }
    // button and label hover
    .ql-picker-label,
    button {
      &:hover,
      &:focus {
        color: #001b97 !important;
        .ql-stroke {
          stroke: #001b97 !important;
        }
        .ql-fill {
          fill: #001b97 !important;
        }
      }
      &.ql-active {
        color: #001b97 !important;
      }
    }
    .ql-picker-item.ql-selected {
      color: #001b97 !important;
    }
    // quill dropdown item hover
    .ql-picker-options {
      .ql-picker-item:hover {
        color: #001b97 !important;
      }
      .ql-active {
        color: #001b97 !important;
      }
    }
  }
}
.ql-bubble {
  .ql-picker {
    color: #FFFFFF !important;
  }
  // stroke options color
  .ql-stroke {
    stroke: #FFFFFF !important;
  }
  // fill options color
  .ql-fill {
    fill: #FFFFFF !important;
  }
}
.ql-container {
  border-color: #d8d6de !important;
  font-family: 'Montserrat', Helvetica, Arial, serif;
}
.ql-editor {
  a {
    color: #001b97;
  }
}
// default picker options
.ql-picker {
  color: #5e5873 !important;
}
// stroke options color
.ql-stroke {
  stroke: #5e5873 !important;
}
.ql-active {
  .ql-stroke {
    stroke: #001b97 !important;
  }
  .ql-fill {
    fill: #001b97 !important;
  }
}
// fill options color
.ql-fill {
  fill: #5e5873 !important;
}
// Border rounded for editor
.ql-toolbar,
.ql-container {
  border-top-right-radius: 0.357rem;
  border-top-left-radius: 0.357rem;
}
.ql-toolbar {
  + .ql-container,
  .ql-container + & {
    // ? If container/toolbar is below container/toolbar add bottom radius
    border-bottom-right-radius: 0.357rem;
    border-bottom-left-radius: 0.357rem;
    // ? If container/toolbar is above container/toolbar remove top radius
    border-top-right-radius: unset;
    border-top-left-radius: unset;
  }
}

// Dark Layout
.dark-layout {
  // Quill Editor
  .quill-toolbar,
  .ql-toolbar {
    background-color: #283046;
    border-color: #3b4253 !important;

    // toolbar color
    .ql-picker {
      color: #FFFFFF !important;
    }

    .ql-stroke {
      stroke: #FFFFFF !important;
    }

    .ql-fill {
      fill: #FFFFFF !important;
    }

    // toolbar options colors
    .ql-picker-options,
    .ql-picker-label {
      background-color: #283046;
      .ql-active {
        color: #001b97 !important;
      }
    }
  }
  .ql-active {
    .ql-stroke {
      stroke: #001b97 !important;
    }
    .ql-fill {
      fill: #001b97 !important;
    }
  }
  .ql-bubble {
    .ql-toolbar {
      background: #3b4253;
      border-radius: 2rem;
    }
  }

  .ql-container {
    border-color: #3b4253 !important;
    background-color: #283046;
  }

  // syntax color
  .ql-editor {
    .ql-syntax {
      background-color: #161d31;
    }

    &.ql-blank {
      &:before {
        color: #b4b7bd;
      }
    }
  }
}

// RTL
[data-textdirection='rtl'] {
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) {
    i,
    svg {
      left: auto !important;
      right: 0;
    }
  }
}
tr {
    border-bottom: 1px solid #707070;
    border-collapse: collapse;
    td {
        padding: 5px 15px;
        font-size: 14px;
    }
}

table {
    width: 100%;
    thead {
        &:nth-child(1), &:nth-child(3) {
            background: #C7EBD5;
        }
        &:nth-child(2), &:nth-child(4) {
            background: #98E0B3;
        }

        th {
            padding: 5px 15px;
        }
    }
}
.modal-content {
    background-color: transparent !important;
    border: none !important;
    overflow-y: scroll;
}


.stat-item {
  .label {
    color: #47C1BF;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .value {
    color: #161d31;
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
  }
}